import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import instance from "api/globalaxios";
import { useSnackbar } from "contexts/SnackbarContext";
import CircularCenteredLoader from "features/common/CircularCenteredLoader";
import FormattedMessage from "features/common/TranslatedMessage";
import deleteSvg from "images/delete.svg";
import { DropzoneDialog } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import {
  approvedDispute,
  CloseDisputeAtLevelTwo,
  downloadLegalDoc,
  gethost,
  getRealhost,
  sendPaymentLinkRespondent,
  updateDispute,
} from "slices/disputeSlice";
import { formatDate } from "utils";
import CaseAssignInput from "./CaseAssignInput";
import CaseDisplayProfileList from "./CaseDisplayProfileList";
import CaseManager from "./CaseManager";
import Company from "./Company";
import Complainant from "./Complainant";
import DetailsAndUpdates from "./Details&Updates";
import GeneralSebiActivities from "./GeneralSebiActivities";
import GRO from "./GRO";
import RespondentLawyer from "./Respondentlawyer";
import SRB from "./SRB";
import Summary from "./Summary";
import Witness from "./Witness";
import CloseModalButton from "Admin/Common/CloseModalButton";
import { isMSME } from "Routes/Routes";

const useStyles = makeStyles((theme) => ({
  backbtn: {
    fontSize: "18px",
    lineHeight: "21px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "15px",
    },
    display: "flex",
  },
  bigbtn: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "0.4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "15px",
    },
  },
  closedbtn: {
    background: "#E0E0E0",
    color: "white",
    "&:hover": {
      backgroundColor: "#E0E0E0",
    },
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    letterSpacing: "0.4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "15px",
    },
  },
  blueheading: {
    fontWeight: "bold",
    fontSize: "20px",
    // lineHeight: "36px",
    color: "#2F80ED",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  awardpaper: {
    margin: "3rem auto",
    height: "80%",
    width: "70%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "80%",
      padding: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
      height: "80%",
      padding: "0.5rem",
    },
  },
  decision: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#4F4F4F",
    wordBreak: "break-all",
  },
  decisionDate: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#4F4F4F",
  },
  username: {
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.2px",
    color: "#252733",
  },
  activityText: {
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: `'Inter',sans-serif`,
    color: "#4F4F4F",
  },
}));

export const closed_reason_choices = [
  { value: "unsuccessful", name: "Unsuccessful" },
  { value: "successful", name: "Successful" },
  { value: "withdraw", name: "Withdrawn" },
];

function getNewCasefiles(dispute_id) {
  return async () => {
    try {
      const response = await instance.get(
        `/tiacassigndessigncasefile/${dispute_id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateDisputesNameForUserApi(user_id, host) {
  return async () => {
    try {
      const response = await instance.get(
        `/update_disputes_name_for_user/?user_id=${user_id}&host=${host}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

export default function CaseDisplay({
  setOpenCase,
  isSmallScreen,
  caseId,
  mode,
  profile,
  reloadOverview,
}) {
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "auto",
      },
      500
    );
  }, []);

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateDisputeName = () => {
    dispatch(getNewCasefiles(caseId)).then((res) => {
      let c_casefiles = [];
      let r_casefiles = [];
      res.map((item) => {
        if (item.type == "claimant") c_casefiles.push(item);
        else r_casefiles.push(item);
      });
      c_casefiles.sort(function (a, b) {
        var nameA = a.id;
        var nameB = b.id;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      r_casefiles.sort(function (a, b) {
        var nameA = a.id;
        var nameB = b.id;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      let name = "";
      if (c_casefiles.length > 1) {
        name +=
          c_casefiles[0].profile.user.first_name +
          " " +
          c_casefiles[0].profile.user.last_name +
          " and others v. ";
      } else if (c_casefiles.length == 1) {
        if (c_casefiles[0].profile == null) {
          name += " v. ";
        } else {
          name +=
            c_casefiles[0].profile.user.first_name +
            " " +
            c_casefiles[0].profile.user.last_name +
            " v. ";
        }
      }

      if (r_casefiles.length > 1) {
        name +=
          r_casefiles[0].profile.user.first_name +
          " " +
          r_casefiles[0].profile.user.last_name +
          " and others";
      } else if (r_casefiles.length == 1) {
        if (r_casefiles[0].profile == null) {
          name += " v. ";
        } else {
          name +=
            r_casefiles[0].profile.user.first_name +
            " " +
            r_casefiles[0].profile.user.last_name;
        }
      }

      dispatch(
        updateDispute({
          id: caseId,
          name: name,
        })
      ).then(() => {
        reloadOverview();
      });
    });
  };

  const [dispute, setDispute] = useState();
  const [isDisputeClosed, setIsDisputeClosed] = React.useState();
  const [startDate, setStartDate] = useState();
  const [approvedDate, setApprovedDate] = useState("---");
  const [summary, setSummary] = useState();
  const [complainantData, setcomplainantData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [groData, setgroData] = useState();
  const [lawyerData, setLawyerData] = useState();
  const [lawyerDataRV, setLawyerDataRV] = useState();
  const [casefiles, setCasefiles] = useState();
  const [witnessData, setwitnessData] = useState();
  const [casemanagerData, setCasemanagerData] = useState();
  const [allProfiles, setAllProfiles] = useState();
  const [loading, setLoading] = useState(true);

  const [allAssignedPrifiles, setallAssignedPrifiles] = useState([]);

  const [ClaimantLawyerData, setClaimantLawyerData] = useState([]);
  const [RespondentLawyerData, setRespondentLawyerData] = useState([]);

  const [commonFiles, setCommonFiles] = useState([]);
  const [decisions, setdecisions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [successState, setSuccessState] = useState("unsuccessful");
  const [selectedUserId, setSelectedUserId]= useState("")

  const handleSuccessStateChange = (e) => {
    setSuccessState(e.target.value);
  };

  const handleSelectUserId = (e) => {
    setSelectedUserId(e.target.value);
  };

  const refresh = () => {
    dispatch(disputeDetails()).then(() => {});
    setOpenAwardFinal(false);
    setOpenAwardPartial(false);
    // dispatch(joinHearingRooms(caseId));
  };

  React.useEffect(() => {
    refresh();
  }, []);

  function disputeDetails() {
    return async () => {
      try {
        setLoading(true);
        const response = await instance.get(
          `/api/tiacrvdisputedetailsbyid/${caseId}/`
        );
        let response2 = await instance({
          method: "get",
          url: "/api/casefile/" + "?host=" + gethost(),
          params: {
            dispute__id: caseId,
          },
        });
        // dispatch(getActivityByTabAndId('general',caseId)).then((res) => {
        //   setActivities(res);
        // });
        let casefilesres = response2.data.results;
        setCasefiles(casefilesres);

        let casefile_respondent = [];
        let new_cl_lawyer = [];
        let new_rsp_lawyer = [];
        casefilesres.sort(function (a, b) {
          var nameA = a.id;
          var nameB = b.id;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        let cl_index = 0;
        let resp_index = 0;
        casefilesres.forEach((casefile) => {
          if (casefile.type == "claimant") {
            casefile_respondent.push(casefile);
            if (casefile.lawyer_rv.length == 0) {
              new_cl_lawyer.push({
                index: cl_index,
                casefile: casefile,
                lawyer: null,
              });
            }
            casefile.lawyer_rv.forEach((i) => {
              new_cl_lawyer.push({
                index: cl_index,
                casefile: casefile,
                lawyer: i,
                all: casefile.lawyer_rv,
              });
            });
            cl_index += 1;
          } else {
            casefile_respondent.push(casefile);
            if (casefile.lawyer_rv.length == 0) {
              new_rsp_lawyer.push({
                index: resp_index,
                casefile: casefile,
                lawyer: null,
              });
            }
            casefile.lawyer_rv.forEach((i) => {
              new_rsp_lawyer.push({
                index: resp_index,
                casefile: casefile,
                lawyer: i,
                all: casefile.lawyer_rv,
              });
            });
            resp_index += 1;
          }
        });
        setClaimantLawyerData(new_cl_lawyer);
        setRespondentLawyerData(new_rsp_lawyer);

        let d = response.data;
        setDispute(d.dispute_data.dispute[0]);
        setCommonFiles(
          d.dispute_data.all_docs.filter((item) => item.is_rfa_rv)
        );
        setdecisions(d.dispute_data.all_docs.filter((item) => !item.is_rfa_rv));
        setIsDisputeClosed(d.dispute_data.dispute[0].status == "closed");
        setSummary(d.dispute_data.dispute[0].summery);
        setcomplainantData(d.dispute_data.claimant);
        if (d.dispute_data.respondent.length > 0)
          setCompanyData(d.dispute_data.respondent);
        else setCompanyData([]);
        let resArbitrator = [];
        let resLawyer = [];
        let resWitness = [];
        let professionals = d.dispute_data.professionals;
        professionals.forEach((item) => {
          if (
            item.profile.profile_type == "arbitrator" ||
            item.profile.profile_type == "arbitrator_rv" ||
            item.profile.profile_type == "mediator"
          ) {
            resArbitrator.push(item);
          } else if (
            item.profile.profile_type == "lawyer" ||
            item.profile.profile_type == "lawyer_rv"
          ) {
            resLawyer.push(item);
          } else if (
            item.profile.profile_type == "witness" ||
            item.profile.profile_type == "witness_rv"
          ) {
            resWitness.push(item);
          }
        });
        setgroData(resArbitrator);
        setLawyerData(resLawyer);
        setwitnessData(resWitness);
        setCasemanagerData(d.dispute_data.case_manager_rv);
        let c = d.dispute_data.claimant;
        let resultProfiles = [];
        if (d.dispute_data.respondent.length > 0) {
          d.dispute_data.respondent.forEach((item) => {
            let r = item;
            r.profile.rv_profile = "Respondent";
            resultProfiles.push(r);
          });
        }
        if (d.dispute_data.claimant.length > 0) {
          d.dispute_data.claimant.forEach((item) => {
            let r = item;
            r.profile.rv_profile = "Claimant";
            resultProfiles.push(r);
          });
        }
        d.dispute_data.professionals.forEach((item) => {
          resultProfiles.push(item);
        });
        setAllProfiles(resultProfiles);
        let date;
        if (d.dispute_data.dispute[0].flow_type === "arbitration") {
          date = new Date(
            d.dispute_data.dispute[0].converted_to_arbitration_at
          );
        } else {
          date = new Date(d.dispute_data.dispute[0].created);
        }
        setStartDate(date.toLocaleDateString("en-US"));
        if (d.dispute_data.dispute[0].approved) {
          date = new Date(d.dispute_data.dispute[0].approved_date);
          setApprovedDate(date.toLocaleDateString("en-US"));
        }
        let assignedProfiles = [];
        assignedProfiles = assignedProfiles
          .concat(d.dispute_data.claimant)
          .concat(d.dispute_data.respondent)
          .concat(resArbitrator)
          .concat(resLawyer)
          .concat(d.dispute_data.case_manager_rv)
          .concat(resWitness);
        setallAssignedPrifiles(assignedProfiles);
        setLoading(false);
        return response.data;
      } catch (error) {
        console.log(error);
      }
    };
  }

  const shareDecision = (id) => {
    return async () => {
      try {
        const response = await instance.put(
          "/tiacfinaldecession/" + id + "/host=" + getRealhost(),
          { dispute_id: dispute.id },
          {
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
  };

  const [loadingRegisterApi, setLoadingRegisterApi] = useState(false);
  const { showSnackbar } = useSnackbar();

  const [openRFA, setOpenRFA] = useState(false);
  const openPopupRFA = () => setOpenRFA(true);
  const closeRFA = () => {
    setOpenRFA(false);
    refresh();
  };

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [openRegister, setOpenRegister] = React.useState(false);
  const [openAwardFinal, setOpenAwardFinal] = useState(false);
  const openPopupAwardFinal = () => setOpenAwardFinal(true);
  const closeAwardFinal = () => {
    setOpenAwardFinal(false);
    setFiles([]);
    refresh();
  };
  const [openAwardPartial, setOpenAwardPartial] = useState(false);
  const openPopupAwardPartial = () => setOpenAwardPartial(true);
  const closeAwardPartial = () => {
    setOpenAwardPartial(false);
    setFiles([]);
    refresh();
  };

  const [loadingfiles, setLoadingfiles] = useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const addFile = (file) => {
    files.push(file);
    setFiles(files);
  };
  const deleteFile = (index) => {
    let localFiles = [...files];
    localFiles.splice(index, 1);
    setFiles(localFiles);
  };

  const renderFiles = (files, deleteFile) => {
    return files.length !== 0
      ? files.map((file, index) => {
          return (
            <Box className={classes.itempaper}>
              <Box
                textAlign="center"
                position="relative"
                key={index}
                // display="flex"
                // justifyContent="center"
              >
                <Box>
                  <Typography
                    style={{ wordBreak: "break-all", paddingTop: "20px" }}
                    variant="subtitle2"
                  >
                    {file.name}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      deleteFile(index);
                    }}
                  >
                    <DeleteOutlineIcon style={{ color: "#2F80ED" }} />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          );
        })
      : null;
  };

  function uploadDispute(files, activeDispute, type) {
    return async () => {
      const data = new FormData();
      files.forEach((file) => {
        data.append(file.name, file);
      });
      try {
        const response = await instance.put(
          "/uploaddispute/" + activeDispute.id + "/?award_type=" + type,
          data,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
      } catch (error) {
        console.log(error);
      }
    };
  }

  const uploadDecision = (type) => {
    setLoadingfiles(true);
    dispatch(uploadDispute(files, dispute, type))
      .then(() => {
        setLoadingfiles(false);
        setFiles([]);
        if (type == "final_award") {
          closeAwardFinal();
        } else {
          closeAwardPartial();
        }
      })
      .catch(() => {
        setLoadingfiles(false);
      });
  };

  const openpopup2 = () => {
    setOpen2(true);
  };

  const openpopup3 = () => {
    setOpen3(true);
  };

  const openpopupRegister = () => {
    setOpenRegister(true);
  };

  const closeModal2 = () => {
    setOpen2(false);
  };

  const closeModal3 = () => {
    setOpen3(false);
  };

  const closeModalRegister = () => {
    setOpenRegister(false);
  };

  const handleMoveToArbitration = async () => {
    try {
      setLoading(true);
      const response = await instance.post(
        `/sebi/convert_to_arbitration/${dispute.id}/`
      );
      setLoading(false);
      showSnackbar("Moved to Arbitration", "success");
      refresh();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const ApproveDispute = () => {
    if (!loadingRegisterApi) {
      if (dispute.rv_tiac_payment_status == "complete") {
        setLoadingRegisterApi(true);
        dispatch(
          approvedDispute({
            id: caseId,
          })
        ).then(() => {
          closeModalRegister();
          refresh();
          reloadOverview();
          setLoadingRegisterApi(false);
        });
      } else {
        alert(
          "Please confirm that the Claimant has paid the registration fees before registering the arbitration."
        );
      }
    }
  };

  const closeGrievence = async () => {
    try {
      // Close the dispute first
      await dispatch(CloseDisputeAtLevelTwo(caseId, successState));
  
      if(dispute?.flow_type == 'mediation'){
        // Call the second API (send_payment_link_respondent)  
        await sendPaymentLinkRespondent(dispute?.tiac_custom_dispute_id, successState, selectedUserId);

      }
      // Reload the overview and update the state
      reloadOverview();
      setIsDisputeClosed(true);
      setOpen2(false);
    } catch (error) {
      console.error("Error in closing grievance:", error);
    }
  };
  

  async function deleteDecisionId(id) {
    return await instance.delete(`/arbitrator_decisiondelete/${id}`);
  }

  function completepayment(disputeId) {
    return async () => {
      try {
        let response = await instance({
          method: "get",
          url: `/completepayment/${disputeId}/`,
        });
        return response.data;
      } catch (error) {
        console.log("error.request.status", error.request.status);
        console.log("error.message", error.message);
      }
    };
  }
  const handlePayment = () => {
    dispatch(completepayment(dispute.id)).then(() => {
      refresh();
    });
  };

  if (loading) {
    return <CircularCenteredLoader />;
  } else {
    return (
      <Grid
        container
        justify="flex-start"
        direction="column"
        style={{ padding: "1rem" }}
      >
        <Grid item>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                onClick={() => {
                  setOpenCase(false);
                  sessionStorage.removeItem("tiac_overview_caseid");
                  sessionStorage.removeItem("isSebiDispute");
                  sessionStorage.removeItem("subAdminInSuperAdminOverviewStep");
                }}
                className={classes.backbtn}
                startIcon={<ArrowBackIosIcon />}
              >
                Back to all cases
              </Button>
            </Grid>
            <Grid item>
              <Typography className={classes.username}>
                {profile.user.first_name} {profile.user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Box height="2rem"></Box>
          {/* {!isSmallScreen() ? ( */}
          <>
            <Grid
              container
              direction="row"
              alignItems="stretch"
              style={{ display: "flex" }}
              spacing={2}
            >
              <Grid item sm={10} xs={12} style={{ flex: "1" }}>
                <Paper
                  style={{
                    height: "100%",
                    background: "none",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography className={classes.blackheading}>
                        CASE ID:{" "}
                        {dispute.approved ? (
                          <CaseAssignInput
                            tiac_custom_dispute_id={
                              dispute.tiac_custom_dispute_id
                            }
                            caseId={caseId}
                          />
                        ) : (
                          "---"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.blackheading}>
                        Start Date: {startDate}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.blackheading}>
                        Registration Date: {approvedDate}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-evenly"
              direction="row"
              alignItems="stretch"
              // style={{ display: "flex" }}
              spacing={3}
            >
              <Grid item md={7} sm={8} xs={12}>
                <Summary summary={summary} caseId={caseId} />
              </Grid>
              <Grid item md={5} sm={4} xs={9}>
                <Grid
                  container
                  direction="column"
                  justify="space-evenly"
                  spacing={2}
                >
                  <Grid item style={{ width: "90%" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.bigbtn}
                      style={
                        isDisputeClosed || !dispute.approved
                          ? {
                              width: "100%",
                              background: "rgba(0,0,0,0.12)",
                              color: "rgba(0,0,0,0.26)",
                            }
                          : {
                              width: "100%",
                              background: "#2F80ED",
                              color: "#fff",
                            }
                      }
                      onClick={() => {
                        history.push(`/disputes/${caseId}/chat`);
                      }}
                      disabled={isDisputeClosed || !dispute.approved}
                    >
                      <FormattedMessage id="common.hearingRooms" />
                    </Button>
                  </Grid>
                  <Grid item style={{ width: "90%" }}>
                    <Button
                      variant="contained"
                      // color="primary"
                      className={classes.bigbtn}
                      size="large"
                      style={{
                        color: "black",
                        background: "white",
                        width: "100%",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        openpopup3();
                        // history.push(`/disputes/${activeDispute.id}/chat`);
                      }}
                    >
                      Details &amp; Updates
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
          <Box height="4rem"></Box>
          <Grid container justify="flex-start" spacing={2} wrap="wrap">
            {complainantData.length == 0 && (
              <Grid
                item
                sm={6}
                md={6}
                lg={4}
                style={{ width: "100%", display: "flex" }}
              >
                <Complainant
                  isSmallScreen={isSmallScreen}
                  groData={null}
                  caseId={caseId}
                  mode={mode}
                  index={null}
                  refresh={refresh}
                  lawyerDataRV={lawyerDataRV}
                  allAssignedPrifiles={allAssignedPrifiles}
                  dispute={dispute}
                  casefiles={casefiles}
                  updateDisputeName={updateDisputeName}
                  group={"sebi"}
                />
              </Grid>
            )}
            {complainantData.map((complainant, index) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ width: "100%", display: "flex" }}
                  key={index}
                >
                  <Complainant
                    isSmallScreen={isSmallScreen}
                    groData={complainant}
                    caseId={caseId}
                    mode={mode}
                    index={index}
                    refresh={refresh}
                    lawyerDataRV={lawyerDataRV}
                    allAssignedPrifiles={allAssignedPrifiles}
                    dispute={dispute}
                    updateDisputeName={updateDisputeName}
                    casefiles={casefiles}
                    group={"sebi"}
                  />
                </Grid>
              );
            })}
            {companyData.length == 0 && (
              <Grid
                item
                sm={6}
                md={6}
                lg={4}
                style={{ width: "100%", display: "flex" }}
              >
                <Company
                  isSmallScreen={isSmallScreen}
                  groData={null}
                  caseId={caseId}
                  mode={mode}
                  index={null}
                  refresh={refresh}
                  lawyerDataRV={lawyerDataRV}
                  allAssignedPrifiles={allAssignedPrifiles}
                  dispute={dispute}
                  updateDisputeName={updateDisputeName}
                  casefiles={casefiles}
                  group={"sebi"}
                />
              </Grid>
            )}
            {companyData.map((respondent, index) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ width: "100%", display: "flex" }}
                  key={index}
                >
                  <Company
                    isSmallScreen={isSmallScreen}
                    groData={respondent}
                    caseId={caseId}
                    mode={mode}
                    index={index}
                    refresh={refresh}
                    lawyerDataRV={lawyerDataRV}
                    allAssignedPrifiles={allAssignedPrifiles}
                    dispute={dispute}
                    updateDisputeName={updateDisputeName}
                    casefiles={casefiles}
                    group={"sebi"}
                  />
                </Grid>
              );
            })}

            {groData.length == 0 && (
              <Grid
                item
                sm={6}
                md={6}
                lg={4}
                style={{ width: "100%", display: "flex" }}
              >
                <GRO
                  isSmallScreen={isSmallScreen}
                  groData={null}
                  preGroData={groData}
                  caseId={caseId}
                  mode={mode}
                  index={null}
                  refresh={refresh}
                  allAssignedPrifiles={allAssignedPrifiles}
                  dispute={dispute}
                  group="sebi"
                  profileType={
                    dispute.flow_type == "mediation"
                      ? "mediator"
                      : "arbitrator_rv"
                  }
                />
              </Grid>
            )}
            {groData.map((item, index) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ width: "100%", display: "flex" }}
                  key={index}
                >
                  <GRO
                    isSmallScreen={isSmallScreen}
                    groData={item}
                    preGroData={groData}
                    caseId={caseId}
                    mode={mode}
                    index={index}
                    refresh={refresh}
                    allAssignedPrifiles={allAssignedPrifiles}
                    dispute={dispute}
                    group={"sebi"}
                    profileType={
                      dispute.flow_type == "mediation"
                        ? "mediator"
                        : "arbitrator_rv"
                    }
                  />
                </Grid>
              );
            })}
            {ClaimantLawyerData.length == 0 && (
              <Grid
                item
                sm={6}
                md={6}
                lg={4}
                style={{ width: "100%", display: "flex" }}
              >
                <SRB
                  isSmallScreen={isSmallScreen}
                  caseId={caseId}
                  groData={null}
                  mode={mode}
                  lawyerDataRV={lawyerDataRV}
                  casefile={casefiles}
                  index={null}
                  refresh={refresh}
                  allAssignedPrifiles={allAssignedPrifiles}
                  dispute={dispute}
                  prevLaw={[]}
                  group={"sebi"}
                />
              </Grid>
            )}
            {ClaimantLawyerData.map((clitem, index) => {
              if (
                lawyerData.find(
                  (element) => element.profile.id == clitem.lawyer
                )
              ) {
                let item =
                  lawyerData[
                    lawyerData.findIndex(
                      (element) => element.profile.id == clitem.lawyer
                    )
                  ];
                return (
                  <Grid
                    item
                    sm={6}
                    md={6}
                    lg={4}
                    style={{ width: "100%", display: "flex" }}
                    key={item.profile.id}
                  >
                    <SRB
                      isSmallScreen={isSmallScreen}
                      caseId={caseId}
                      groData={item}
                      mode={mode}
                      lawyerDataRV={lawyerDataRV}
                      casefile={clitem.casefile}
                      index={clitem.index}
                      refresh={refresh}
                      allAssignedPrifiles={allAssignedPrifiles}
                      dispute={dispute}
                      prevLaw={clitem.all}
                      group={"sebi"}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    item
                    sm={6}
                    md={6}
                    lg={4}
                    style={{ width: "100%", display: "flex" }}
                    key={"empty" + index}
                  >
                    <SRB
                      isSmallScreen={isSmallScreen}
                      caseId={caseId}
                      groData={null}
                      mode={mode}
                      lawyerDataRV={lawyerDataRV}
                      casefile={clitem.casefile}
                      index={clitem.index}
                      refresh={refresh}
                      allAssignedPrifiles={allAssignedPrifiles}
                      dispute={dispute}
                      prevLaw={[]}
                      group={"sebi"}
                    />
                  </Grid>
                );
              }
            })}
            {RespondentLawyerData.length == 0 && (
              <Grid
                item
                sm={6}
                md={6}
                lg={4}
                style={{ width: "100%", display: "flex" }}
              >
                <RespondentLawyer
                  isSmallScreen={isSmallScreen}
                  caseId={caseId}
                  groData={null}
                  mode={mode}
                  lawyerDataRV={lawyerDataRV}
                  casefile={casefiles}
                  index={null}
                  refresh={refresh}
                  allAssignedPrifiles={allAssignedPrifiles}
                  dispute={dispute}
                  prevLaw={[]}
                  group={"sebi"}
                />
              </Grid>
            )}
            {RespondentLawyerData.map((clitem, index) => {
              if (
                lawyerData.find(
                  (element) => element.profile.id == clitem.lawyer
                )
              ) {
                let item =
                  lawyerData[
                    lawyerData.findIndex(
                      (element) => element.profile.id == clitem.lawyer
                    )
                  ];
                return (
                  <Grid
                    item
                    sm={6}
                    md={6}
                    lg={4}
                    style={{ width: "100%", display: "flex" }}
                    key={item.profile.id}
                  >
                    <RespondentLawyer
                      isSmallScreen={isSmallScreen}
                      caseId={caseId}
                      groData={item}
                      mode={mode}
                      lawyerDataRV={lawyerDataRV}
                      casefile={clitem.casefile}
                      index={clitem.index}
                      refresh={refresh}
                      allAssignedPrifiles={allAssignedPrifiles}
                      dispute={dispute}
                      prevLaw={clitem.all}
                      group={"sebi"}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    item
                    sm={6}
                    md={6}
                    lg={4}
                    style={{ width: "100%", display: "flex" }}
                    key={"empty" + index}
                  >
                    <RespondentLawyer
                      isSmallScreen={isSmallScreen}
                      caseId={caseId}
                      groData={null}
                      mode={mode}
                      lawyerDataRV={lawyerDataRV}
                      casefile={clitem.casefile}
                      index={clitem.index}
                      refresh={refresh}
                      allAssignedPrifiles={allAssignedPrifiles}
                      dispute={dispute}
                      prevLaw={[]}
                      group={"sebi"}
                    />
                  </Grid>
                );
              }
            })}
            {witnessData.length === 0 && (
              <Grid
                item
                sm={6}
                md={6}
                lg={4}
                style={{ width: "100%", display: "flex" }}
              >
                <Witness
                  groData={null}
                  caseId={caseId}
                  index={null}
                  refresh={refresh}
                  dispute={dispute}
                  allAssignedPrifiles={allAssignedPrifiles}
                  casefiles={casefiles}
                  group={"sebi"}
                />
              </Grid>
            )}
            {witnessData.map((item, index) => {
              return (
                <Grid
                  item
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ width: "100%", display: "flex" }}
                >
                  <Witness
                    groData={item}
                    caseId={caseId}
                    index={index}
                    refresh={refresh}
                    dispute={dispute}
                    allAssignedPrifiles={allAssignedPrifiles}
                    casefiles={casefiles}
                    group={"sebi"}
                  />
                </Grid>
              );
            })}

            {casemanagerData.length === 0 &&
              (profile.profile_type === "sub_admin_tiac_rv" ||
                profile.profile_type === "admin") && (
                <Grid
                  item
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ width: "100%", display: "flex" }}
                >
                  <CaseManager
                    isSmallScreen={isSmallScreen}
                    groData={null}
                    preGroData={groData}
                    caseId={caseId}
                    mode={mode}
                    index={null}
                    refresh={refresh}
                    allAssignedPrifiles={allAssignedPrifiles}
                    dispute={dispute}
                    group={"sebi"}
                  />
                </Grid>
              )}
            {(profile.profile_type == "sub_admin_tiac_rv" ||
              profile.profile_type === "admin") &&
              casemanagerData.map((item, index) => {
                return (
                  <Grid
                    item
                    sm={6}
                    md={6}
                    lg={4}
                    style={{ width: "100%", display: "flex" }}
                    key={index}
                  >
                    <CaseManager
                      isSmallScreen={isSmallScreen}
                      groData={item}
                      preGroData={groData}
                      caseId={caseId}
                      mode={mode}
                      index={index}
                      refresh={refresh}
                      allAssignedPrifiles={allAssignedPrifiles}
                      dispute={dispute}
                      group={"sebi"}
                    />
                  </Grid>
                );
              })}
            {/* <Grid
              item
              sm={6}
              md={6}
              lg={4}
              style={{ width: "100%", display: "flex" }}
            >
              <CaseManager
                isSmallScreen={isSmallScreen}
                casemanagerData={casemanagerData}
                caseId={caseId}
                mode={mode}
                refresh={refresh}
                allAssignedPrifiles={allAssignedPrifiles}
              />
            </Grid> */}
          </Grid>
          <Box height="4rem"></Box>
          <Grid container spacing={2}>
            {isMSME && (
              <Grid item lg={4} md={6} sm={6} xs={6}>
                <Button
                  variant="contained"
                  className={classes.bigbtn}
                  size="large"
                  style={{
                    color: "black",
                    background: "white",
                    width: "100%",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    openPopupRFA();
                  }}
                >
                  RFA
                </Button>
              </Grid>
            )}
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                // color="primary"
                className={classes.bigbtn}
                size="large"
                style={
                  dispute.approved
                    ? {
                        color: "black",
                        background: "grey",
                        width: "100%",
                        textTransform: "none",
                      }
                    : {
                        color: "black",
                        background: "white",
                        width: "100%",
                        textTransform: "none",
                      }
                }
                onClick={() => {
                  openpopupRegister();
                }}
                disabled={dispute.approved}
              >
                Register Case
              </Button>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                // color="primary"
                className={classes.bigbtn}
                size="large"
                style={{
                  color: "black",
                  background: "white",
                  width: "100%",
                  textTransform: "none",
                }}
                onClick={() => {
                  history.push(`/disputes/${caseId}/files/0`);
                }}
              >
                Files
              </Button>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                // color="primary"
                className={classes.bigbtn}
                size="large"
                style={{
                  color: "black",
                  background: "white",
                  width: "100%",
                  textTransform: "none",
                }}
                onClick={() => {
                  openPopupAwardPartial();
                }}
              >
                Partial Award
              </Button>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                // color="primary"
                className={classes.bigbtn}
                size="large"
                style={{
                  color: "black",
                  background: "white",
                  width: "100%",
                  textTransform: "none",
                }}
                onClick={() => {
                  openPopupAwardFinal();
                }}
              >
                Final Award
              </Button>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                // color="primary"
                className={classes.bigbtn}
                size="large"
                style={
                  dispute.flow_type != "mediation"
                    ? {
                        color: "black",
                        background: "grey",
                        width: "100%",
                        textTransform: "none",
                      }
                    : {
                        color: "black",
                        background: "white",
                        width: "100%",
                        textTransform: "none",
                      }
                }
                onClick={handleMoveToArbitration}
                disabled={dispute.flow_type != "mediation"}
              >
                Move to Arbitration
              </Button>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={6}>
              <Button
                variant="contained"
                // color="primary"
                className={classes.bigbtn}
                size="large"
                style={
                  isDisputeClosed
                    ? {
                        color: "black",
                        background: "grey",
                        width: "100%",
                        textTransform: "none",
                      }
                    : {
                        color: "black",
                        background: "white",
                        width: "100%",
                        textTransform: "none",
                      }
                }
                onClick={() => {
                  openpopup2();
                }}
                disabled={isDisputeClosed}
              >
                Close Case
              </Button>
            </Grid>
          </Grid>
          <Box height="5rem"></Box>
          <Grid container justify="flex-start">
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>NAME</Typography>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>
                PROFILE TYPE
              </Typography>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>CALENDAR</Typography>
            </Grid>
            <Grid item sm={3} xs={3}>
              <Typography className={classes.blueheading}>DRIVE</Typography>
            </Grid>
          </Grid>
          <Box height="0.4rem"></Box>
          {allProfiles.map((profile) => {
            return (
              <CaseDisplayProfileList
                key={profile.profile.id}
                profile={profile}
                caseId={caseId}
                mode={mode}
              />
            );
          })}
        </Grid>

        <Box height="2rem" />
        <Grid container>
          <Grid item md={10} sm={11} xs={12}>
            <GeneralSebiActivities
              activities={activities}
              setActivities={setActivities}
              isGetActivityByTabAndId={true}
              disputeId={caseId}
              activityTitle="Case Activity"
            />
            <Box height="2rem" />
          </Grid>
        </Grid>

        <Popup
          open={openRegister}
          closeOnDocumentClick
          closeOnEscape
          onClose={closeModalRegister}
          position="center"
          modal
          nested
          contentStyle={{
            background: "white",
            width: "330px",
            height: "185px",
          }}
          overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
        >
          <Box style={{ padding: "1rem 1.3rem" }}>
            <Typography
              style={{
                fontSize: "20px",
                color: "#333333",
                "font-weight": "bold",
                "line-height": "23px",
                "letter-spacing": "0.0015em",
              }}
            >
              Are you sure?
            </Typography>
            <Box height="0.5rem"></Box>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                "line-height": "28px",
              }}
            >
              Are you sure you want to register this case?
            </Typography>
            <Box height="0.7rem"></Box>
          </Box>
          <Box style={{ padding: "1rem 1.3rem" }}>
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    "line-height": "16px",
                    "letter-spacing": "0.02em",
                    "font-weight": "500",
                    cursor: "pointer",
                  }}
                  onClick={closeModalRegister}
                >
                  CANCEL
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={
                    loadingRegisterApi
                      ? {
                          fontSize: "14px",
                          color: "#2F80ED",
                          "line-height": "16px",
                          "letter-spacing": "0.02em",
                          "font-weight": "500",
                          cursor: "wait",
                        }
                      : {
                          fontSize: "14px",
                          color: "#2F80ED",
                          "line-height": "16px",
                          "letter-spacing": "0.02em",
                          "font-weight": "500",
                          cursor: "pointer",
                        }
                  }
                  onClick={ApproveDispute}
                >
                  REGISTER
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Popup>
        <Popup
          open={open2}
          closeOnDocumentClick
          closeOnEscape
          onClose={closeModal2}
          position="center"
          modal
          nested
          contentStyle={{
            background: "white",
            width: "330px",
            height: "auto",
          }}
          overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
        >
          <Box style={{ padding: "1rem 1.3rem" }}>
            <Typography
              style={{
                fontSize: "20px",
                color: "#333333",
                "font-weight": "bold",
                "line-height": "23px",
                "letter-spacing": "0.0015em",
              }}
            >
              Are you sure?
            </Typography>
            <Box height="0.5rem"></Box>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                "line-height": "28px",
              }}
            >
              Are you sure you want to close this case?
            </Typography>
            <Box height="0.7rem"></Box>
            <Typography
              style={{
                fontSize: "16px",
                color: "#828282",
                "line-height": "28px",
              }}
            >
              Mark case as successful, unsuccessful or withdrawn
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <FormControl variant="outlined" data-testid={dispute.id}>
                <InputLabel id="sadf">Select</InputLabel>
                <Select
                  id={dispute.id}
                  name={dispute.id}
                  value={successState}
                  onChange={handleSuccessStateChange}
                  label="Select"
                  style={{ textAlign: "left" }}
                >
                  {closed_reason_choices.map((item) => {
                    return (
                      <MenuItem
                        id={item.value}
                        value={item.value}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

            {dispute?.flow_type == 'mediation' && (
              <FormControl variant="outlined" style={{width: '100px'}}>
              <InputLabel id="sadf">Select</InputLabel>
              <Select
                labelId="select-label"
                value={selectedUserId}
                onChange={handleSelectUserId}
                label="Select"
                style={{ textAlign: "right" }}
              >
                {allProfiles?.map((profileName) => {
                  return (
                    <MenuItem
                      id={profileName?.user?.id}
                      value={profileName?.user?.id}
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      {profileName?.user?.first_name} {profileName?.user?.last_name}
                    </MenuItem>
                  );
                })}
              </Select>
              </FormControl>
            )}
            </div>
          </Box>
          <Box style={{ padding: "1rem 1.3rem" }}>
            <Grid container direction="row" justify="flex-end" spacing={2}>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#828282",
                    "line-height": "16px",
                    "letter-spacing": "0.02em",
                    "font-weight": "500",
                    cursor: "pointer",
                  }}
                  onClick={closeModal2}
                >
                  CANCEL
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: "#2F80ED",
                    "line-height": "16px",
                    "letter-spacing": "0.02em",
                    "font-weight": "500",
                    cursor: "pointer",
                  }}
                  onClick={closeGrievence}
                >
                  CLOSE
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Popup>
        <Popup
          open={open3}
          closeOnDocumentClick
          closeOnEscape
          onClose={closeModal3}
          position="center"
          modal
          nested
          contentStyle={{
            background: "white",
            maxWidth: "80rem",
            width: "70%",
            minHeight: "70vh",
            textAlign: "left",
          }}
          overlayStyle={{ background: "rgba(51, 51, 51, 0.7)" }}
        >
          <DetailsAndUpdates
            dispute={dispute}
            allAssignedProfiles={allAssignedPrifiles}
          />
        </Popup>
        <Modal
          open={openAwardFinal}
          onClose={closeAwardFinal}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openAwardFinal}>
            <Paper className={classes.awardpaper}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Final Awards
              </Typography>
              <Box height="1rem" />
              <Grid container>
                <Grid item sm={7} xs={5} style={{ textAlign: "left" }}>
                  <Typography className={classes.decision}>
                    Document Name
                  </Typography>
                </Grid>
                <Grid item sm xs style={{ textAlign: "left" }}>
                  <Typography className={classes.decisionDate}>
                    Upload Date
                  </Typography>
                </Grid>
              </Grid>
              <Box height="1rem" />
              {decisions.map((item) => {
                if (!item.is_rfa_rv && item.award_type == "final_award")
                  return (
                    <React.Fragment key={item.id}>
                      <Grid container>
                        <Grid item sm={7} xs={5} style={{ textAlign: "left" }}>
                          <Typography
                            className={classes.decision}
                            onClick={() => {
                              downloadLegalDoc(item.id);
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm
                          xs
                          style={{ margin: "auto", textAlign: "left" }}
                        >
                          <Typography>{formatDate(item.created)}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ margin: "auto", textAlign: "center" }}
                        >
                          {item.is_final ? (
                            <Typography className={classes.decisionDate}>
                              Shared
                            </Typography>
                          ) : (
                            <Typography
                              style={{ cursor: "pointer" }}
                              className={classes.decisionDate}
                              onClick={() => {
                                dispatch(shareDecision(item.id));
                                closeAwardFinal();
                                refresh();
                              }}
                            >
                              Share
                            </Typography>
                          )}
                        </Grid>
                        <Grid item style={{ textAlign: "left" }}>
                          <img
                            src={deleteSvg}
                            alt="delete"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              deleteDecisionId(item.id);
                              refresh();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
              })}
              <Box height="2rem" />
              {files.length === 0 && (
                <Grid container justify="center">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setOpenUpload(true);
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {renderFiles(files, deleteFile)}
              <Button
                variant="contained"
                style={{ width: "224px", background: "#2F80ED" }}
                onClick={() => {
                  uploadDecision("final_award");
                }}
                disabled={files.length < 1 || loadingfiles}
              >
                <Typography style={{ color: "#fff" }}>SUBMIT</Typography>
              </Button>
              <DropzoneDialog
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                maxFileSize={104857600}
                open={openUpload}
                onClose={() => setOpenUpload(false)}
                onSave={(files) => {
                  files.forEach((file) => {
                    addFile(file);
                  });
                  setOpenUpload(false);
                }}
                showPreviews={true}
                filesLimit="1"
                showFileNamesInPreview={true}
                acceptedFiles={[
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "application/msword",
                  "application/pdf",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ]}
              />
            </Paper>
          </Fade>
        </Modal>
        <Modal
          open={openAwardPartial}
          onClose={closeAwardPartial}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openAwardPartial}>
            <Paper className={classes.awardpaper}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Partial Awards
              </Typography>
              <Box height="1rem" />
              <Grid container>
                <Grid item sm={7} xs={5} style={{ textAlign: "left" }}>
                  <Typography className={classes.decision}>
                    Document Name
                  </Typography>
                </Grid>
                <Grid item sm xs style={{ textAlign: "left" }}>
                  <Typography className={classes.decisionDate}>
                    Upload Date
                  </Typography>
                </Grid>
              </Grid>
              <Box height="1rem" />
              {decisions.map((item) => {
                if (!item.is_rfa_rv && item.award_type == "partial_award")
                  return (
                    <React.Fragment key={item.id}>
                      <Grid container>
                        <Grid item sm={7} xs={5} style={{ textAlign: "left" }}>
                          <Typography
                            className={classes.decision}
                            onClick={() => {
                              downloadLegalDoc(item.id);
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sm
                          xs
                          style={{ margin: "auto", textAlign: "left" }}
                        >
                          <Typography>{formatDate(item.created)}</Typography>
                        </Grid>
                        <Grid
                          item
                          xs
                          style={{ margin: "auto", textAlign: "center" }}
                        >
                          {item.is_final ? (
                            <Typography className={classes.decisionDate}>
                              Shared
                            </Typography>
                          ) : (
                            <Typography
                              style={{ cursor: "pointer" }}
                              className={classes.decisionDate}
                              onClick={() => {
                                dispatch(shareDecision(item.id));
                                closeAwardPartial();
                                refresh();
                              }}
                            >
                              Share
                            </Typography>
                          )}
                        </Grid>
                        <Grid item style={{ textAlign: "left" }}>
                          <img
                            src={deleteSvg}
                            alt="delete"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              deleteDecisionId(item.id);
                              refresh();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
              })}
              <Box height="2rem" />
              {files.length === 0 && (
                <Grid container justify="center">
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setOpenUpload(true);
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
              {renderFiles(files, deleteFile)}
              <Button
                variant="contained"
                style={{ width: "224px", background: "#2F80ED" }}
                onClick={() => {
                  uploadDecision("partial_award");
                }}
                disabled={files.length < 1 || loadingfiles}
              >
                <Typography style={{ color: "#fff" }}>SUBMIT</Typography>
              </Button>
              <DropzoneDialog
                cancelButtonText={"cancel"}
                submitButtonText={"submit"}
                maxFileSize={104857600}
                open={openUpload}
                onClose={() => setOpenUpload(false)}
                onSave={(files) => {
                  files.forEach((file) => {
                    addFile(file);
                  });
                  setOpenUpload(false);
                }}
                showPreviews={true}
                filesLimit="1"
                showFileNamesInPreview={true}
                acceptedFiles={[
                  "image/jpeg",
                  "image/png",
                  "image/jpg",
                  "application/msword",
                  "application/pdf",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ]}
              />
            </Paper>
          </Fade>
        </Modal>
        <Modal
          open={openRFA}
          onClose={closeRFA}
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableEnforceFocus
        >
          <Fade in={openRFA}>
            <Paper className={classes.awardpaper}>
              <CloseModalButton onClick={closeRFA} />
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                RFA
              </Typography>
              <Box height="1rem" />
              {commonFiles.map((item) => {
                if (item.is_rfa_rv && item.award_type === "submission_report")
                  return (
                    <React.Fragment key={item.id}>
                      <Grid container>
                        <Grid item xs={12}>
                          <MenuItem
                            onClick={() => {
                              downloadLegalDoc(item.id);
                            }}
                          >
                            {"Submission_Receipt_from_Samaadhan_Portal" + item.name}
                          </MenuItem>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                else if (item.is_rfa_rv && item.award_type === "arbitration_petition")
                  return (
                    <React.Fragment key={item.id}>
                      <Grid container>
                        <Grid item xs={12}>
                          <MenuItem
                            onClick={() => {
                              downloadLegalDoc(item.id);
                            }}
                          >
                            {"Arbitration_Petition_" + item.name}
                          </MenuItem>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                  else if (item.is_rfa_rv && item.award_type === "msme_certificate")
                    return (
                      <React.Fragment key={item.id}>
                        <Grid container>
                          <Grid item xs={12}>
                            <MenuItem
                              onClick={() => {
                                downloadLegalDoc(item.id);
                              }}
                            >
                              {"MSME_Certificate_of_Applicant_" + item.name}
                            </MenuItem>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                else if (item.is_rfa_rv)
                  return (
                    <React.Fragment key={item.id}>
                      <Grid container>
                        <Grid item xs={12}>
                          <MenuItem
                            onClick={() => {
                              downloadLegalDoc(item.id);
                            }}
                          >
                            {item.name}
                          </MenuItem>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
              })}
            </Paper>
          </Fade>
        </Modal>
      </Grid>
    );
  }
}
