import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { isHotStar } from "Routes/Routes";
import React, { useEffect, useState } from "react";

const platformList = [
  { value: "arb", name: "Arb" },
  { value: "arbitrationconference", name: "Arbitration Conference" },
  { value: "cdr", name: "CDR" },
  { value: "difc", name: "DIFC / Moot" },
  { value: "digipub", name: "Digipub" },
  { value: "kilumaya", name: "Kilumaya" },
  { value: "msme", name: "MSME" },
  { value: "muthoot", name: "Muthoot" },
  { value: "nbf", name: "NBF" },
  { value: "nse", name: "NSE" },
  { value: "orix", name: "Orix"},
  { value: "rv", name: "RV" },
  { value: "sebi", name: "SEBI" },
  { value: "star", name: "Star" },
  {value:"tdsat", name: "TDSAT"},
  { value: "tiac", name: "TIAC" },
  { value: "tiacstatic", name: "TIAC Static" },
  { value: "vikalp", name: "Vikalp / Sahmati" },
  { value: "webnyay", name: "Webnyay" },
];

export default function DevSelectPlatform() {
  const t = localStorage.getItem("dev");
  const [platform, setplatform] = useState(t ? t : "webnyay");

  useEffect(() => {
    if (!t) {
      if (isHotStar) {
        localStorage.setItem("dev", "hotstar");
      } else {
        localStorage.setItem("dev", "webnyay");
        window.location.reload();
      }
    }
  }, [t]);

  const handleChange = (event) => {
    setplatform(event.target.value);
    localStorage.setItem("dev", event.target.value);
    localStorage.removeItem("i8_lang");
    window.location.reload();
  };
  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Platform</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={platform}
        label="PLatform."
        onChange={handleChange}
      >
        {platformList.map((platform) => (
          <MenuItem value={platform.value}>{platform.name}</MenuItem>
        ))}
        {window.location.hostname === "localhost" && (
          <MenuItem value="hotstar">JioHotstar</MenuItem>
        )}
      </Select>
    </FormControl>
  );
}
