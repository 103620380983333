import { Button, Grid, Snackbar, TextField } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import { updateDisputesNameForUserApi } from "Admin/Sebi/CaseDisplay";
import instance from "api/globalaxios";
import { blockInvalidChar } from "features/TempTiacFormV2/ClaimantPage/questions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { isSebi } from "Routes/Routes";
import { getRealhost } from "slices/disputeSlice";
import { getCountries } from "slices/profileSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "3rem auto",
    height: "80%",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    outline: "none",
    borderRadius: "0.4rem",
    overflowY: "scroll",
  },
  editprofile: {
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "23px",
    letterSpacing: "0.6px",
    color: "#454545",
  },
}));

export default function EditProfileRow({ profileData, dispute_edit, refresh }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const haveProfile = profileData.profile ? true : false;

  const [allCountries, setallCountries] = useState([]);
  React.useEffect(() => {
    getCountries().then((res) => {
      let result = [...res];
      result.sort(function (a, b) {
        var nameA = a.name.toUpperCase();
        var nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setallCountries(result);
    });
  }, []);

  const [id, setid] = useState(
    haveProfile ? profileData.profile.id : profileData.id
  );
  const user_id = profileData.user.id;
  const [firstname, setFirstname] = useState(profileData.user.first_name);
  const [lastname, setLastname] = useState(profileData.user.last_name);
  const [phone, setPhone] = useState(
    haveProfile ? profileData.profile.phone_number : profileData.phone_number
  );
  const [email, setEmail] = useState(profileData.user.email);
  const [address, setAddress] = useState(
    haveProfile ? profileData.profile.address : profileData.address
  );
  const [city, setcity] = useState(
    haveProfile ? profileData.profile.city : profileData.city
  );
  const [region, setregion] = useState(
    haveProfile ? profileData.profile.region : profileData.region
  );
  const [postal_code, setpostal_code] = useState(
    haveProfile ? profileData.profile.postal_code : profileData.postal_code
  );
  const [gst_number, setgst_number] = useState(
    haveProfile ? profileData.profile.gst_number : profileData.gst_number
  );
  const [country, setCountry] = useState(
    dispute_edit ? profileData.profile.country_id : profileData.country
  );

  React.useEffect(() => {
    if (!country) {
      setCountry("-");
    }
  }, []);

  const onFirstNameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const onCityChange = (e) => {
    setcity(e.target.value);
  };
  const onRegionChange = (e) => {
    setregion(e.target.value);
  };
  const onPostalCodeChange = (e) => {
    setpostal_code(e.target.value);
  };
  const onGstNumberChange = (e) => {
    setgst_number(e.target.value);
  };

  const [openSnackBar1, setOpenSnackBar1] = useState(false);
  const handleCloseSnackBar1 = () => {
    setOpenSnackBar1(false);
  };
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const handleCloseSnackBar2 = () => {
    setOpenSnackBar2(false);
    refresh();
  };
  const [openSnackBar3, setOpenSnackBar3] = useState(false);
  const handleCloseSnackBar3 = () => {
    setOpenSnackBar3(false);
  };
  const [openSnackBar4, setOpenSnackBar4] = useState(false);
  const handleCloseSnackBar4 = () => {
    setOpenSnackBar4(false);
  };
  const [openSnackBar5, setOpenSnackBar5] = useState(false);
  const handleCloseSnackBar5 = () => {
    setOpenSnackBar5(false);
  };

  async function updateProfile(profileData) {
    let {
      id,
      phone_number,
      address,
      city,
      region,
      postal_code,
      country,
      user,
      gst_number,
    } = profileData;
    return await instance({
      method: "patch",
      url: `/api/profile/${id}/`,
      data: {
        phone_number: phone_number,
        address: address,
        city: city,
        region: region,
        postal_code: postal_code,
        gst_number: gst_number,
        country: country,
        user,
      },
    });
  }

  const onSubmit = () => {
    const profileData = {
      id: id,
      phone_number: phone && phone.trim() != "" ? phone : null,
      address: address,
      city: city,
      region: region,
      postal_code: postal_code,
      gst_number: gst_number,
      country: country && country != "-" ? country : null,
      user: {
        first_name: firstname,
        last_name: lastname,
        email: email,
      },
    };

    if (
      !firstname ||
      // !lastname ||
      !email ||
      firstname.trim() == ""
      // lastname.trim() == ""
    ) {
      setOpenSnackBar3(true);
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(email)) {
        setOpenSnackBar4(true);
      } else {
        updateProfile(profileData)
          .then(() => {
            setOpenSnackBar2(true);
            dispatch(
              updateDisputesNameForUserApi(user_id, getRealhost())
            )
          })
          .catch((error) => {
            console.log(error.response.data);
            if (error.response.data.user && error.response.data.user.email) {
              alert(error.response.data.user.email[0]);
            }
            if (error.response.data.phone_number) {
              alert(error.response.data.phone_number[0]);
            }
          });
        // let countspace = (phone.match(/ /g) || []).length;
        // if (countspace > 0) {
        //   if (countspace > 1) {
        //     setOpenSnackBar5(true);
        //   } else {
        //     if (/^\d+$/.test(phone.split(" ")[1])) {
        //       dispatch(updateProfile(profileData)).then((res) => {
        //       });
        //     } else {
        //       setOpenSnackBar5(true);
        //     }
        //   }
        // } else {
        //   if (/^\d+$/.test(phone)) {
        //     dispatch(updateProfile(profileData)).then((res) => {
        //     });
        //   } else {
        //     setOpenSnackBar5(true);
        //   }
        // }
      }
    }
  };

  return (
    <>
      <Grid container justify="space-evenly" spacing={2}>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First Name"
            variant="outlined"
            fullWidth={true}
            value={firstname}
            onChange={onFirstNameChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            // required
            id="lastName"
            name="lastName"
            label="Last Name"
            variant="outlined"
            fullWidth={true}
            value={lastname}
            onChange={onLastNameChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="phone"
            name="phone"
            label="Phone Number"
            type="number"
            variant="outlined"
            fullWidth={true}
            value={phone}
            onKeyDown={blockInvalidChar}
            onChange={onPhoneChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            required
            id="email"
            name="email"
            label="Email ID"
            variant="outlined"
            fullWidth={true}
            value={email}
            onChange={onEmailChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="Apartment and Street"
            name="Apartment and Street"
            label="Apartment and Street"
            variant="outlined"
            fullWidth={true}
            value={address}
            onChange={onAddressChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="city"
            name="city"
            label="Сity"
            variant="outlined"
            fullWidth={true}
            value={city}
            onChange={onCityChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="region"
            name="region"
            label="Region and State"
            variant="outlined"
            fullWidth={true}
            value={region}
            onChange={onRegionChange}
          />
        </Grid>
        <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="postal_code"
            name="postal_code"
            label="Postal Code"
            variant="outlined"
            fullWidth={true}
            value={postal_code}
            onChange={onPostalCodeChange}
          />
        </Grid>
       {isSebi && <Grid item md={5} sm={10} xs={12}>
          <TextField
            id="gst_number"
            name="gst_number"
            label="Gst Number"
            variant="outlined"
            fullWidth={true}
            value={gst_number}
            onChange={onGstNumberChange}
          />
        </Grid>}
        <Grid item md={5} sm={10} xs={12}>
          <FormControl fullWidth>
            <Select
              labelId="demo-country-select-label"
              id="demo-country-select"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <MenuItem value={"-"}>---</MenuItem>
              {allCountries.map((country) => {
                return (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box height="1rem"></Box>
          <Typography className={classes.text2}>Country</Typography>
        </Grid>
      </Grid>
      <Box height="2rem"></Box>
      <Button
        variant="contained"
        style={{ background: "#2F80ED", color: "#fff" }}
        size="large"
        className={classes.bigbtn}
        onClick={onSubmit}
      >
        Save
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar1}
        onClose={handleCloseSnackBar1}
        message="Problem in creating guest profile."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Problem in creating guest profile.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar2}
        onClose={handleCloseSnackBar2}
        message="Guest profile is created."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="success">
          Profile is edited.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar3}
        onClose={handleCloseSnackBar3}
        message="Please enter all the details."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="warning">
          Please enter all the details.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar4}
        onClose={handleCloseSnackBar4}
        message="Please enter valid email address."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter valid email address.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar5}
        onClose={handleCloseSnackBar5}
        message="Please enter valid phone number."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar1} severity="error">
          Please enter valid phone number.
        </Alert>
      </Snackbar>
    </>
  );
}
