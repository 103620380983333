import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import bluepenSvg from "images/bluepenTiac.svg";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deAssignProfile } from "./ADRP";
import Assign, { joinHearingRooms } from "./Assign";
import CreateLoginDetails from "./CreateLoginDetails";
import EditProfile from "./EditProfile";
import { isSebi } from "Routes/Routes";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "35px",
    textAlign: "center",
    borderRadius: "4px",
    // width: "425px",
    // height: "360px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    wordBreak: "break-all",
    [theme.breakpoints.down("lg")]: {
      padding: "15px",
    },
  },
  headingblue: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#2F80ED",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  bluetext: {
    fontSize: "20px",
    lineHeight: "36px",
    color: "#2F80ED",
    textDecorationLine: "underline",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  blackheading: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.4px",
    color: "#454545",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  text: {
    // fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "36px",
    color: "#454545",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "20px",
    },
  },
  space: {
    height: "1rem",
    [theme.breakpoints.down("lg")]: {
      height: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "3rem",
    },
  },
  space2: {
    height: "9.3rem",
    [theme.breakpoints.down("md")]: {
      height: "8rem",
    },
  },
}));

export default function SRB({
  groData,
  caseId,
  companyId,
  mode,
  casefile,
  index,
  refresh,
  allAssignedPrifiles,
  dispute,
  prevLaw,
  group,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   lawyerDataRV.map((item) => {
  //     if (item.respondent_lawyer) {
  //     } else {
  //       setClaimantLawyerData(item.claimant_lawyer);
  //     }
  //   });
  // }, []);

  // const refresh = () => {
  //   dispatch(disputeDetails());
  // };

  const [openmodal, setOpenmodal] = useState(false);

  const handleOpenModal = () => {
    setOpenmodal(true);
  };

  const handleCloseModal = () => {
    setOpenmodal(false);
    refresh();
  };

  const [openmodal2, setOpenmodal2] = useState(false);

  const handleOpenModal2 = () => {
    setOpenmodal2(true);
  };

  const handleCloseModal2 = () => {
    setOpenmodal2(false);
  };

  const [openmodal3, setOpenmodal3] = useState(false);

  const handleOpenModal3 = () => {
    setOpenmodal3(true);
  };

  const handleCloseModal3 = () => {
    setOpenmodal3(false);
    refresh();
  };
  const [dispute_edit, setDispute_edit] = useState(true);
  const handleCloseModalAfterProfileCreated = () => {
    setOpenmodal2(false);
    setOpenSnackBar2(true);
  };
  const [openSnackBar2, setOpenSnackBar2] = useState(false);
  const handleCloseSnackBar2 = () => {
    setOpenSnackBar2(false);
  };

  return (
    <Paper className={classes.paper}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.headingblue}>
            {typeof index === "number"
              ? "CLAIMANT " + (index + 1) + " 'S COUNSEL"
              : "CLAIMANT'S COUNSEL(S)"}
          </Typography>
        </Grid>
        <Grid item>
          <img
            src={bluepenSvg}
            alt="pen image"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              if (groData) handleOpenModal3();
              else alert("There are no profiles to edit");
            }}
          />
        </Grid>
      </Grid>
      <Box height="1rem"></Box>
      <Grid container spacing={3}>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal}
          >
            Assign
          </Typography>
        </Grid>
        {groData && (
          <Grid item style={{ textAlign: "left" }}>
            <Typography
              className={classes.bluetext}
              style={{ cursor: "pointer" }}
              onClick={() => {
                deAssignProfile(groData.profile.id, caseId, casefile.id).then(
                  () => {
                    refresh();
                    dispatch(joinHearingRooms(caseId));
                  }
                );
              }}
            >
              Remove
            </Typography>
          </Grid>
        )}
      </Grid>
      {typeof index === "number" && groData ? (
        <>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.blackheading}>
                {groData.user.first_name} {groData.user.last_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Email: {groData.user.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Phone: {groData.profile.phone_number}{" "}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Address:{" "}
                {groData.profile.address ? groData.profile.address + ", " : ""}
                {groData.profile.city ? groData.profile.city + ", " : ""}
                {groData.profile.region ? groData.profile.region + ", " : ""}
                {groData.profile.postal_code ? groData.profile.postal_code : ""}
              </Typography>
            </Grid>
          </Grid> */}
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Apartment and Street:{" "}
                {groData.profile.address ? groData.profile.address + ", " : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Сity : {groData.profile.city ? groData.profile.city + ", " : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Region and State:{" "}
                {groData.profile.region ? groData.profile.region + ", " : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Postal code:{" "}
                {groData.profile.postal_code
                  ? groData.profile.postal_code + ", "
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Country:{" "}
                {groData.country != null ? groData.country.name + ", " : ""}
              </Typography>
            </Grid>
          </Grid>
          {isSebi && <Grid container>
            <Grid item style={{ textAlign: "left" }}>
              <Typography className={classes.text}>
                Gst Number:{" "}
                {groData.profile.gst_number
                  ? groData.profile.gst_number + ", "
                  : ""}
              </Typography>
            </Grid>
          </Grid>}
          <Box className={classes.space}></Box>
        </>
      ) : (
        <>
          <Box className={classes.space2}></Box>
        </>
      )}
      {openmodal && (
        <Assign
          openmodal={openmodal}
          handleCloseModal={handleCloseModal}
          caseId={caseId}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
          groData={groData}
          casefile={casefile}
          caseType_RV={"claimant"}
          allAssignedPrifiles={allAssignedPrifiles}
          dispute={dispute}
          prevLaw={prevLaw}
        />
      )}

      <Grid container>
        <Grid item style={{ textAlign: "left" }}>
          <Typography
            className={classes.bluetext}
            style={{ cursor: "pointer" }}
            onClick={handleOpenModal2}
          >
            + Create login details
          </Typography>
        </Grid>
      </Grid>
      {openmodal2 && (
        <CreateLoginDetails
          openmodal={openmodal2}
          handleCloseModal={handleCloseModal2}
          caseId={caseId}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
          companyId={companyId}
          dispute={dispute}
          handleCloseModalAfterProfileCreated={
            handleCloseModalAfterProfileCreated
          }
          group={group}
        />
      )}
      {openmodal3 && (
        <EditProfile
          openmodal={openmodal3}
          handleCloseModal={handleCloseModal3}
          profileData={groData}
          profileType={mode == "RV" ? "lawyer_rv" : "lawyer"}
          dispute_edit={dispute_edit}
          refresh={refresh}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        autoHideDuration={5000}
        open={openSnackBar2}
        onClose={handleCloseSnackBar2}
        message="Guest profile is created."
        // key={vertical + horizontal}
      >
        <Alert onClose={handleCloseSnackBar2} severity="success">
          Profile is created.
        </Alert>
      </Snackbar>
    </Paper>
  );
}
