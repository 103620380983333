import {
    Box,
    Button,
    Grid,
    makeStyles,
    Paper,
    Typography,
    IconButton,
    Checkbox,
  } from "@material-ui/core";
  import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
  import instance from "api/globalaxios";
  import Axios from "axios";
  import { format } from "date-fns";
  import CircularCenteredLoader from "features/common/CircularCenteredLoader";
  import DeletedPopup from "features/common/Popup/DeletedPopup";
  import DonePopup from "features/common/Popup/DonePopup";
  import POPUP from "features/common/Popup/Popup";
  import debounce from "lodash.debounce";
  import React, { useCallback, useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  import { useHistory } from "react-router-dom";
  import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
  import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
  import { isMuthoot } from "Routes/Routes";
import Tabs from "features/Tabs/Tabs";
import { Add, DateRangeOutlined, PrintOutlined } from "@material-ui/icons";
import DropdownButton from "Components/DropdownButton";
// import DisputeRowMuthoot from "./DisputeRowMuthoot";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { enGB } from "date-fns/locale";
import Popup from "reactjs-popup";
import { ARBITRATOR, INDIAN_STATES, MEDIATOR } from "app/constants";
import SearchBar from "Components/SearchBar";
import Table from "Components/Table/Table";
import DateRangePicker from "Components/DateRangePicker";
import { download_MIS_Report } from "../API/OrixApi";
import validateDateAndFormat from "utils/validateDateAndFormat";
import fileDownload from "js-file-download";
  
  export async function changeDisputeState(disputeId, state) {
    return await instance.get(
      `/changedisputestatehotstar/${disputeId}/${state}/`
    );
  }
  export async function deleteDisputeapi(disputeId) {
    return await instance.delete(`/deletedisputehotstar/${disputeId}/`);
  }
  
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "3rem",
      [theme.breakpoints.down("sm")]: {
        padding: "1.5rem",
      },
    },
    sidebar: {
      padding: "1rem",
      userSelect: "none",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    content: {
      [theme.breakpoints.down("sm")]: {
        marginTop: "3rem",
      },
    },
    bigheading: {
      color: isMuthoot ? 'black' : "#4F4F4F",
      // fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "36px",
      textTransform: "capitalize",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    searchbar: {
      border: "none",
      background: "#DADBDB",
      width: "-webkit-fill-available",
      color: "#828282",
      fontSize: "14px",
      outline: "none",
      padding: "0rem 1.5rem",
      height: "42px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
        height: "34px",
        padding: "0rem 0.7rem",
      },
    },
    typeButton: {
      textTransform: "none",
      border: "1px solid #4F4F4F",
      borderRadius: "0",
      padding: "0 1.5rem",
      color: "#4F4F4F",
      backgroundColor: 'white',
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      height: "42px",
  
      [theme.breakpoints.down("xs")]: {
        height: "34px",
        fontSize: "14px",
      },
    },
    typeButtonBlue: {
      textTransform: "none",
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
      borderRadius: "0",
      padding: "0 1.5rem",
      color: "#fff",
      // fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "18px",
      height: "42px",
      "&:hover": {
        background: "#FFFFFF",
        color: isMuthoot ? theme.palette.primary.main : "#2F80ED",
        border: "1px solid",
        borderColor: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xs")]: {
        height: "34px",
        fontSize: "14px",
      },
    },
    typechoices: {
      color: "#333333",
      fontFamily: `'Inter',sans-serif`,
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18px",
      textTransform: "capitalize",
      paddingRight: "5px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "12px",
      },
    },
    typeFormControl: {
      margin: 0,
      "&:hover": {
        background: "#E0E0E0",
      },
    },
    spaceBox: {
      height: "4rem",
      [theme.breakpoints.down("sm")]: {
        height: "3rem",
      },
      [theme.breakpoints.down("xs")]: {
        height: "2rem",
      },
    },
    griditem: {
      padding: "8px",
      [theme.breakpoints.down("xs")]: {
        padding: "4px",
      },
    },
    title: { 
      fontFamily: 'Satoshi-Bold',
      fontSize: "14px",
      lineHeight: "17px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      },
    },
    value: {
      fontSize: "14px",
      lineHeight: "17px",
      textTransform: "capitalize",
      "&:hover": {
        background: "#E0E0E0",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
        lineHeight: "1.2rem",
      },
    },
    titlePaper: {
      padding: "1.5rem",
      boxShadow: "none",
      width: "100%",
      backgroundColor: "initial",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    valuePaper: {
      padding: "1.5rem",
      cursor: "pointer",
      boxShadow: "none",
      width: "100%",
      border: "1px solid #E0E0E0",
      "&:hover": {
        background: "#E0E0E0",
      },
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    menuwidth: {
      width: "200px",
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "130px",
      },
    },
    alginCenter: {
      textAlign: "center",
    },
    tabsContainer: {
      width:'70%',
      flexWrap: 'nowrap',
      [theme.breakpoints.down("sm")]: {
        width: '100%',
        flexWrap: 'wrap',
      },
    },
    selected: {
      padding: "0.8rem 1.2rem",
      cursor: 'pointer',
      fontSize: '1.2rem',
      fontFamily: 'Satoshi-Medium',
      minWidth: 'fit-content',
      width: '16.6%',
      textAlign: 'center',
      whiteSpace: 'pre-line',
      color: theme.palette.primary.main,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    unselected: {
      fontSize: '1.2rem',
      padding: "0.8rem 1.2rem",
      cursor: "pointer",
      fontFamily: 'Satoshi-Medium',
      minWidth: 'fit-content',
      width: '16.6%',
      textAlign: 'center',
      whiteSpace: 'pre-line',
      borderBottom: '2px solid black',
    },
    filtersContainer: {
      padding: '1rem 0', 
      display: 'flex', 
      gap: '15px', 
      justifyContent:'center',
      width: '100%',
      margin: 'auto',
    },
    buttonPrint: {
      border: '1px solid #AAAFB9',
      background: '#fff',
      padding: '0.5rem 1rem',
    },
  }));
  
  export function isValidMonth(value) {
    if (
      value == "january" ||
      value == "february" ||
      value == "march" ||
      value == "april" ||
      value == "may" ||
      value == "june" ||
      value == "july" ||
      value == "august" ||
      value == "september" ||
      value == "october" ||
      value == "november" ||
      value == "december"
    ) {
      return true;
    }
    return false;
  }
  export function isValidNumber(value) {
    return !isNaN(value);
  }
  export function isValidDate(dateObject) {
    return new Date(dateObject).toString() !== "Invalid Date";
  }

  function isValidOrixId(input) {
    const regex = /\b(GRC|GRO)-\d+-\d{4}\b/i;
    return regex.test(input);
  }

  export function calc_days(date1, date2 = null) {
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const dateObj1 = new Date(date1);
    const dateObj2 = date2 ? new Date(date2) : new Date();
    const timeDifference = dateObj2 - dateObj1;
    const dayDifference = Math.round(timeDifference / ONE_DAY);
    return dayDifference;
  }
  
  export default function DisputesOrix({
    count,
    disputesLoading,
    setType,
    type,
    disputes,
    reload,
    totalPages,
    currentPage,
    setCurrentPage,
    getUserDataAnotherPage,
    region,
    setRegion,
    profile,
  }) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const VENDOR_REGION = profile?.profile?.region;
    const IS_MEDIATOR = profile?.profile?.profile_type === MEDIATOR;
    const IS_ARBITRATOR = profile?.profile?.profile_type === ARBITRATOR;
    const IS_ADMIN = profile?.profile?.profile_type === 'sub_admin';
    const IS_VENDOR = profile?.profile?.profile_type === 'vendor';
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    const [anchorType, setAnchorType] = React.useState(null);
    const handleClickType = (event) => {
      setAnchorType(event.currentTarget);
    };
    const handleCloseType = () => {
      setAnchorType(null);
    };
  
    const handleTypeChange = (newType) => {
      setType(newType);
      setSelectedDisputes([]);
      handleCloseType();
      setSearchText("");
      sessionStorage.setItem("hotstar-type", newType);
      reload(newType);
    };
  
    const [selectedDisputes, setSelectedDisputes] = useState([]);
    const addDispute = (id) => {
      let res = [];
      selectedDisputes.forEach((i) => {
        res.push(i);
      });
      res.push(id);
      setSelectedDisputes(res);
    };
    const removeDispute = (id) => {
      let res = [];
      selectedDisputes.forEach((i) => {
        res.push(i);
      });
      let index = res.indexOf(id);
      if (index > -1) {
        res.splice(index, 1);
      }
      setSelectedDisputes(res);
    };
  
    const [searchText, setSearchText] = useState("");
    const [disputesBySearch, setDisputesBySearch] = useState(disputes);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [cancelToken, setCancelToken] = useState();
    let firstname = "";
    let lastname = "";
  
    const debouceSearch = useCallback(debounce(search, 500), [disputes]);

    const columns = [
      { field: 'ticketNumber', headerName: 'Ticket Number', width: '16.66%'},
      { field: 'name', headerName: 'Name', width: '16.66 %'},
      { field: 'complainDetails', headerName: 'COMPLAINT DETAILS', width: '33.32%'},
      { field: 'tableStatus', headerName: 'Status', width: '8.33%', align: 'center'},
      { field: 'daysPasses', headerName: 'Days Passed', width: '8.33%', align: 'center'},
      { field: 'timeStamp', headerName: 'Date & Time', width: '16.66 %'},
    ];

    const processedData = (data) => {
      const result = data.reduce((arr, item) => {
        const transformedObject = { 
          ticketNumber: IS_ARBITRATOR ? item.gro_ticket_number : item.grc_ticket_number, 
          name: item.name, 
          daysPasses: calc_days(IS_ARBITRATOR ? item.reopen_date : item.submission_date),
          complainDetails: <Typography className="lineClamp">{item?.description}</Typography>, 
          timeStamp: IS_ARBITRATOR ? format(new Date(item.reopen_date), "dd MMMM, y, h:mm a") : format(new Date(item.submission_date), "dd MMMM, y, h:mm a"),
          tableStatus: item?.gro_ticket_number ? 'GRO' : 'GRC',
          ...item,
        };
        arr.push(transformedObject);
        return arr;
      }, []);
      
      return result;
    };
  
    useEffect(() => {
      setDisputesBySearch(processedData(disputes));
    }, [disputes]);
  
    function search(value, type) {
      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      let x = Axios.CancelToken.source();
      setCancelToken(x);
  
      if (value.trim() != "") {
        let new_value = value.replaceAll('/','-');
        let ar = new_value.trim().split("-");
        let date = new Date(ar[1] + "/" + ar[0] + "/" + ar[2]);
        if (isValidDate(date)) {
          dispatch(getOrixdatabydate(date, x, type, region));
          setLoadingSearch(false);
        } else if (isValidNumber(new_value.trim()) || isValidOrixId(new_value.trim())) {
          dispatch(getOrixdatabyDisputeId(new_value.trim(), x, type, region));
          setLoadingSearch(false);
        } else {
          dispatch(getOrixdatabyname(new_value.trim(), x, type, region));
          setLoadingSearch(false);
        }
      } else {
        setDisputesBySearch(processedData(disputes));
        setLoadingSearch(true);
      }
    }

    function getOrixdatabyname(name, cancelToken, type, region) {
      return async (dispatch) => {
        try {
          const answer_array = name.split(" ");
          firstname = answer_array[0];
          lastname = answer_array[1];
          if (firstname != "" && (lastname == "" || !lastname)) {
            const response2 = await instance.get(
              `/api/orixsearchview/${firstname}/${null}/?type=${type}&region=${region}&days=${'null'}`,
              { cancelToken: cancelToken.token }
            );
            console.log(response2.data);
            setDisputesBySearch(processedData(response2.data));
  
            return response2.data;
          } else {
            const response3 = await instance.get(
              `/api/MuthootsearchView/${firstname}/${lastname}/?type=${type}&region=${region}&days=${'null'}`,
              { cancelToken: cancelToken.token }
            );
            setDisputesBySearch(processedData(response3.data));
            return response3.data.profiles;
          }
        } catch (error) {
          console.log(error);
        }
      };
    }

    function getOrixdatabydate(date, cancelToken, type=null, region=null) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `api/orixsearchbydateView/${format(
              date,
              "dd-MM-yyyy"
            )}/?type=${type}&region=${region}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(processedData(response.data));
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }

    function getOrixdatabyDisputeId(value, cancelToken, type, region) {
      return async (dispatch) => {
        try {
          const response = await instance.get(
            `/api/orixsearchbydisputeidView/${value}/?type=${type}&region=${region}&days=${'null'}`,
            { cancelToken: cancelToken.token }
          );
          setDisputesBySearch(processedData(response.data));
  
          return response.data;
        } catch (error) {
          console.log(error);
        }
      };
    }

    const tabs = [
      {
        id: "totalcases",
        label: "Total Complaints\n " + count.registered.total,
      },
      {
        id: "closed",
        label: "Closed\n " + count.registered.closed,
      },
      {
        id: "open",
        label: "Open\n " + count.registered.open,
      },
    ];

    const [currentTab, setCurrentTab] = useState(type);

    const handleTabChange = (newValue) => {
      setCurrentTab(newValue);
      setType(newValue);
      setSelectedDisputes([]);
      setSearchText("");
      sessionStorage.setItem("muthoot-type", newValue);
      setCurrentPage(1);
      // reload(newValue, region, days);
      // setPagination({
      //   page: 1,
      //   count: activities?.count,
      //   next: activities?.next,
      //   previous: activities?.previous,
      // });
    };

    const handleClickRegionOption = (selectedOption) => {
      const value = selectedOption?.value || selectedOption?.title;
      setRegion((value === region || value === 'Unselect') ? null : value);
    }


    const regionOptions = {
      'north' : [{
        title: 'BIHAR',
        value: 'BIHAR',
      },
      {
        title: 'CHANDIGARH',
        value: 'CHANDIGARH',
      },
      {
        title: 'DELHI',
        value: 'DELHI',
      },
      {
        title: 'HARYANA',
        value: 'HARYANA',
      },
      {
        title: 'HIMACHAL PRADESH',
        value: 'HIMACHAL PRADESH',
      },
      {
        title: 'JAMMU & KASHMIR',
        value: 'JAMMU & KASHMIR',
      },
      {
        title: 'JHARKHAND',
        value: 'JHARKHAND',
      },
      {
        title: 'PUNJAB',
        value: 'PUNJAB',
      },
      {
        title: 'RAJASTHAN',
        value: 'RAJASTHAN',
      },
      {
        title: 'UTTAR PRADESH',
        value: 'UTTAR PRADESH',
      },
      {
        title: 'UTTARAKHAND',
        value: 'UTTARAKHAND',
      }],
      'south' : [{
        title: 'ANDAMAN AND NICOBAR',
        value: 'ANDAMAN AND NICOBAR',
      },
      {
        title: 'ANDHRA PRADESH',
        value: 'ANDHRA PRADESH',
      },
      {
        title: 'KARNATAKA',
        value: 'KARNATAKA',
      },
      {
        title: 'KERALA',
        value: 'KERALA',
      },
      {
        title: 'PONDICHERRY(UT)',
        value: 'PONDICHERRY(UT)',
      },
      {
        title: 'TAMIL NADU',
        value: 'TAMIL NADU',
      },
      {
        title: 'TELANGANA',
        value: 'TELANGANA',
      }],
      'east' : [{
        title: 'ARUNACHAL PRADESH',
        value: 'ARUNACHAL PRADESH',
      },
      {
        title: 'ASSAM',
        value: 'ASSAM',
      },
      {
        title: 'MANIPUR',
        value: 'MANIPUR',
      },
      {
        title: 'ODISHA',
        value: 'ODISHA',
      },
      {
        title: 'SIKKIM',
        value: 'SIKKIM',
      },
      {
        title: 'TRIPURA',
        value: 'TRIPURA',
      },
      {
        title: 'WEST BENGAL',
        value: 'WEST BENGAL',
      }],
      'west' : [{
        title: 'CHHATTISGARH',
        value: 'CHHATTISGARH',
      },
      {
        title: 'DADRA & NAGAR HAVELI',
        value: 'DADRA & NAGAR HAVELI',
      },
      {
        title: 'DAMAN & DIU',
        value: 'DAMAN & DIU',
      },
      {
        title: 'GOA',
        value: 'GOA',
      },
      {
        title: 'GUJARAT',
        value: 'GUJARAT',
      },
      {
        title: 'MADHYA PRADESH',
        value: 'MADHYA PRADESH',
      },
      {
        title: 'MAHARASHTRA',
        value: 'MAHARASHTRA',
      }],
      'ahmedabad' : [{
        title: 'AHMEDABAD',
        value: 'AHMEDABAD',
      }]
    }

    const allRegionsOptions = Object.values(regionOptions).flat();

    const onRowClick = (row) => {
      history.push(`/disputes/${row.id}`);
    };
    
    const handleDownloadReport = () => {
      if(startDate > endDate){
        alert("From date should be less than or equal to To date.");
        return;
      }
      download_MIS_Report(validateDateAndFormat(startDate, 'dd-MM-yyyy'),validateDateAndFormat(endDate, 'dd-MM-yyyy')).then((res)=>{
        // console.log(res);
        fileDownload(
          res.data,
          `MIS_Report_${validateDateAndFormat(startDate, 'dd-MM-yyyy')}_to_${validateDateAndFormat(endDate, 'dd-MM-yyyy')}.xlsx`
        );
      }).catch((error)=>{
        console.log(error);
      })
    }

    return (
      <>
        <Grid container className={classes.root}>
          <Grid container justify="space-between" alignItems="center" style={{gap: '1rem'}}>
            <Tabs
              tabs={tabs}
              handleClickTab={handleTabChange}
              choice={currentTab}
              tabsClasses={{
                selected: classes.selected,
                unselected: classes.unselected,
              }}
              tabsContainerClass={(IS_VENDOR)?classes.tabsContainer:{width:'100%'}}
            />
            {(IS_VENDOR) && (
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add/>}
                  onClick={() => {
                    history.push("/intro");
                  }}
                >
                  CREATE TICKET
                </Button>
              </Box>
            )}
          </Grid>
          <Box className={classes.spaceBox} />
          {disputesLoading ? (
            <CircularCenteredLoader />
          ) : (
            <>
              <Grid container>
                <Grid
                  item
                  className={classes.griditem}
                  style={{ paddingLeft: 0, flex: 1, }}
                >
                  <SearchBar
                    placeholder="Search by Complainant's Name, Date (DD-MM-YYYY) or Ticket Number"
                    value={searchText}
                    onChange={(value) => {
                      setSearchText(value);
                      debouceSearch(value, type);
                    }}
                    backgroundColor="#f5f5f5"
                    height={42}
                    borderRadius={0}
                    showSearchIcon={true}
                    TextFieldProps={{
                      fullWidth: true,
                    }}
                  />
                </Grid>
                <Grid item className={classes.griditem} style={{ paddingRight: 0}}>
                  <DropdownButton
                    buttonText={region ? region : 'Region'}
                    options={[{ title: 'Unselect' }, ...INDIAN_STATES.map(item => ({ title: item }))]}
                    classes={classes}
                    handleClickOption={handleClickRegionOption}
                    selectedOption={region}
                  />
                </Grid>
              </Grid>
              <Box className={classes.filtersContainer} style={{justifyContent:'space-between'}}>
                <DateRangePicker 
                  startDate={startDate} 
                  setStartDate={setStartDate} 
                  endDate={endDate} 
                  setEndDate={setEndDate}
                  maximumStartDate={new Date()}
                  maximumEndDate={new Date()}
                />
                <Button
                  className={classes.buttonPrint}
                  endIcon={<PrintOutlined/>}
                  onClick={handleDownloadReport}
                  disabled={!startDate || !endDate}
                  size="small"
                >
                  Download Report
                </Button>
              </Box>
              {/* Disputes list starts from here */}
              <Box className={classes.spaceBox}></Box>
              <Grid container style={{ rowGap: "8px" }}>
                
                {disputesBySearch.length !== 0 ? (
                  <Table
                    data={disputesBySearch}
                    columns={columns}
                    onRowClick={onRowClick}
                    loading={disputesLoading}
                    customStyles={{ 
                      tableStyle: { marginTop: '2%', border: '1px solid #e0e0e0'},
                      rowStyle: { backgroundColor: 'transparent' }, 
                      cellStyle: { fontFamily: 'Satoshi-Bold' }, 
                    }}
                  />
                ) : (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box textAlign="center">
                        <Typography
                          style={{
                            fontFmaily: '"Inter","sans-serif"',
                            fontWeight: 500,
                            fontSize: "initial",
                          }}
                        >
                          Dispute does not exist.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  }
  