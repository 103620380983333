import { createSlice } from "@reduxjs/toolkit";
import instance from "api/globalaxios";
import { isOrix, isVikalp } from "Routes/Routes";
import { gethost } from "./disputeSlice";

export const initialState = {
  loading: false,
  hasErrors: false,
  user: {},
  errors: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProgress: (state) => {
      state.loading = true;
    },
    setSucessfull: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.hasErrors = false;
      state.errors = {};
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = true;
      state.errors = payload;
    },
  },
});

export const { setProgress, setSucessfull, setError } = userSlice.actions;

export default userSlice.reducer;

export function getUser() {
  return async (dispatch) => {
    dispatch(setProgress());
    instance({
      method: "get",
      url: "/api/user/",
    }).then(
      (response) => {
        dispatch(setSucessfull(response.data));
        return response.data;
      },
      (error) => {
        console.log(error);
        // console.log(error.request.status);
        // console.log(error.message)
        // console.log(error.request.response)
        dispatch(setError());
      }
    );
  };
}

export function register(userData) {
  let {
    email,
    phoneNumber,
    profile_type,
    password,
    birthDate,
    gender,
    firstName,
    lastName,
    otp,
    city,
    state,
    mobileOtp
  } = userData;

  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/signup?host=" + gethost(),
        data: {
          user: { email, password, first_name: firstName, last_name: lastName },

          phone_number: phoneNumber,
          profile_type: profile_type,
          gender,
          birth_date: birthDate,
          mobile_otp: mobileOtp,
          group:isVikalp ? 'vikalp' : null,
          state,
          city,
          ...isOrix ? {
            email_otp: otp,
          } : {
            otp: otp,
          }
        },
      });
      dispatch(setSucessfull(response.data));
      return response.data;
    } catch (error) {
      // console.log("error.message...", error.message);
      // console.log("error.request.status...", error.request.status);
      // console.log("error.request.response...", error.request.response);
      console.log("inside send otp catch block...before response return");
      return JSON.parse(error.request.response);
      // return JSON.parse(error.request.response.data);
      // dispatch(setError(JSON.parse(error.request.response)));
    }
  };
}

export async function Tiaccreateprofile(Data, profile_type) {
  let {
    full_name,
    nationality,
    address,
    city,
    region,
    postal_code,
    phone_number,
    email,
    fax,
    otp,
    country,
    group
  } = Data;
  return await instance({
    method: "post",
    url: "/tiaccreateprofile/",
    data: {
      full_name,
      nationality,
      address,
      city,
      region,
      postal_code,
      phone_number,
      email,
      fax,
      profile_type,
      for: "claimant",
      otp: otp,
      country: country,
      group : group
    },
  });
}

export function TiacLawyercreateprofile(Data) {
  let {
    full_name,

    address,
    city,
    region,
    code,
    phoneNumber,
    email,
    fax,
    otp,
    profile_type,
  } = Data;
  console.log(" 16777777777777777", full_name);

  return async (dispatch) => {
    dispatch(setProgress());
    try {
      let response = await instance({
        method: "post",
        url: "/tiaccreateprofile/",
        data: {
          full_name,

          address,
          city,
          region,
          code,
          phoneNumber,
          email,
          fax,
          profile_type: "lawyer_rv",
          for: "claimant",

          otp: otp,
        },
      });
      console.log(" responseeee&&&&&&&&&&", response.data);

      return response.data;
    } catch (error) {}
  };
}

export async function getCitiesByStateName(state) {
  return instance({
    method: "get",
    url: `/api/cities/${state}/`,
  }).then(
    (response) => {
      return response.data;
    },
    (error) => {
      console.log(error);
      return error;
    }
  );
}
