import { Divider, InputAdornment, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { isOrix, isStar } from "Routes/Routes";
import { isMuthoot } from "Routes/Routes";
import { StyledTextFieldTiac } from "features/TempTiacFormV2/ClaimantPage/questions";
import FormattedMessage from "features/common/TranslatedMessage";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { setLogOut, setTokenAsync } from "slices/loginSlice";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "3rem 7rem",
    [theme.breakpoints.down("md")]: {
      padding: "3rem 5rem",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 3rem",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 2rem",
    },
  },
  secondaryText: {
    color: "#4F4F4F",
    whiteSpace: "initial",
    textAlign: "center",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "26px",
    marginBottom: "3rem",
    marginTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2rem",
      fontSize: "18px",
      marginTop: "0.5rem",
    },
  },
  title: {
    flexGrow: 1,
    "& img": {
      width: "24rem",
      height: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "22rem",
      },
      [theme.breakpoints.down("xs")]: {
        width: "16rem",
      },
    },
  },
  guestbtn: {
    flexGrow: 1,
    background: "#FFFFFF",
    borderRadius: "4px",
    color: "#2F80ED",
    textTransform: "none",
    border: "1px solid #2F80ED",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    marginBottom: "3rem",
    height: "42px",
    "&:hover": {
      background: "#2F80ED",
      color: "#FFFFFF",
      border: "1px solid #2F80ED",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "20px",
      marginBottom: "2rem",
      height: "34px",
    },
  },
  guestbtnNew: {
    flexGrow: 1,
    marginBottom: "3rem",
    height: "42px",
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: "none",
    fontSize: "14px",
    "&:hover": {
      background: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "20px",
      marginBottom: "2rem",
      height: "auto",
    },
  },
  blueTextForButton: {
    color: "#2F80ED",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
  },
  orText: {
    color: "#4F4F4F",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
  },
  forgot: {
    color: "#696F79",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    textDecoration: "underline",
    textTransform: "capitalize",
  },
  login: {
    color: "#4F4F4F",
    textAlign: "center",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "26px",
    marginTop: "2.5rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginTop: "2rem",
      fontSize: "18px",
    },
  },
  loginbtn: {
    flexGrow: 1,
    background: "#2F80ED",
    borderRadius: "4px",
    color: "#FFFFFF",
    textTransform: "none",
    border: "1px solid #2F80ED",
    fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "26px",
    margin: "1rem 0",
    height: "42px",
    "&:hover": {
      background: "#FFFFFF",
      color: "#2F80ED",
      border: "1px solid #2F80ED",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "20px",
      height: "34px",
    },
  },
  loginbtnNew: {
    flexGrow: 1,
    margin: "1rem 0",
    height: "42px",
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: "none",
    fontSize: "14px",
    "&:hover": {
      background: "#FFFFFF",
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      lineHeight: "20px",
      height: "auto",
    },
  },
  poweredby: {
    color: "#000000",
    // fontFamily: `'Inter',sans-serif`,
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "15px",
  },
  inputLabel: {
    color: "#828282",
    "&.focused": {
      color: isMuthoot ? theme.palette.primary.main : "#2F80ED",
    },
  },
}));

const LoginSchema = Yup.object().shape({
  email: Yup.string().trim().email("Invalid email").required("Email Required"),
  password: Yup.string().required("Password Required"),
  // .min(6, "Password Too Short!")
  // .max(20, "Password Too Long!")
});

const FrontPageHotstar = () => {
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { state: routeState } = useLocation();
  const apiErrors = useSelector((state) => state.login.errors);
  const hasErrors = useSelector((state) => state.login.hasErrors);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(setLogOut());
      let { email, password } = values;
      dispatch(setTokenAsync(email, password)).then(() => {
        if (routeState) {
          history.push(`${routeState.from.pathname}`);
        } else {
          history.push("/");
        }
      });
    },
  });

  const [passwordType, setPasswordType] = React.useState("password");
  const tooglePasswordType = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  React.useEffect(() => {
    dispatch(setLogOut()); // to remove login error alert
  }, []);

  return (
    <Grid container justify="center">
      <Grid item xl={5} lg={6} md={7} xs={11}>
        <Paper
          className={classes.paper}
          style={isMuthoot || isOrix ? { border: "1px solid #e5e5e5" } : null}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs />
            <Grid item xs className={classes.title} style={isOrix ? { flex:0 } : null}>
              {isStar ? (
                <img src="/DisneyStar.svg" alt="" />
              ) : (
                <img
                  style={isOrix?{width:'7rem'}:null}
                  src={
                    isMuthoot
                      ? "/Muthoot_Finance_Logo.svg"
                      : isOrix
                      ? "/Orix_logo.png"
                      : "/JioHotstar_Logo_OnWhite.png"
                  }
                  alt=""
                />
              )}
            </Grid>
            <Grid item xs />
          </Grid>
          <Typography className={classes.secondaryText}>
            {isMuthoot || isOrix
              ? "Grievance Redressal Platform"
              : "Content Grievance Redressal System"}
          </Typography>
          
          {!isOrix && (
            <>
              <Button
                variant="outlined"
                color="primary"
                className={
                  isMuthoot || isOrix ? classes.guestbtnNew : classes.guestbtn
                }
                fullWidth
                onClick={() => {
                  history.push("/intro");
                }}
              >
                File your first grievance (guest)
              </Button>

              <Grid container spacing={2}>
                <Grid item xs style={{ margin: "auto" }}>
                  <Divider style={{ background: "#E0E0E0" }} />
                </Grid>
                <Grid item>
                  <Typography className={classes.orText}>or</Typography>
                </Grid>
                <Grid item xs style={{ margin: "auto" }}>
                  <Divider style={{ background: "#E0E0E0" }} />
                </Grid>
              </Grid>
            </>
          )}

          <Typography className={classes.login}>Log in</Typography>
          <StyledTextFieldTiac
            variant="filled"
            margin="normal"
            value={formik.email}
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                formik.handleSubmit();
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
              },
            }}
          />
          {formik.errors.email && formik.touched.email && (
            <Alert style={{ width: "-webkit-fill-available" }} severity="error">
              {formik.errors.email}
            </Alert>
          )}
          {hasErrors && apiErrors.email && (
            <Alert style={{ width: "-webkit-fill-available" }} severity="error">
              {apiErrors.email[0]}
            </Alert>
          )}
          <StyledTextFieldTiac
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={passwordType}
            id="password"
            value={formik.password}
            autoComplete="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={tooglePasswordType}
                  style={{ cursor: "pointer" }}
                >
                  {passwordType === "password" ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                formik.handleSubmit();
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabel,
                focused: "focused",
              },
            }}
          />
          {formik.errors.password && formik.touched.password && (
            <Alert style={{ width: "-webkit-fill-available" }} severity="error">
              {formik.errors.password}
            </Alert>
          )}
          {hasErrors && apiErrors.password && (
            <Alert style={{ width: "-webkit-fill-available" }} severity="error">
              {apiErrors.password[0]}
            </Alert>
          )}
          <Grid container justify="flex-end">
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <Link to="/forgot-password">
                <Typography className={classes.forgot}>
                  <FormattedMessage id="auth.forgot" />
                </Typography>
              </Link>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            className={
              isMuthoot || isOrix ? classes.loginbtnNew : classes.loginbtn
            }
            fullWidth
            onClick={formik.handleSubmit}
          >
            Log in to your{" "}
            {isStar
              ? "Star"
              : isMuthoot
              ? "Muthoot"
              : isOrix
              ? "Orix"
              : "JioHotstar"}{" "}
            grievance redressal account
          </Button>
          {hasErrors && apiErrors.non_field_errors && (
            <>
              <Alert
                style={{ width: "-webkit-fill-available" }}
                severity="error"
              >
                {apiErrors.non_field_errors[0]}
              </Alert>
              <div style={{ height: "1rem" }} />
            </>
          )}
          <Button
            variant="outlined"
            color="primary"
            className={
              isMuthoot || isOrix ? classes.guestbtnNew : classes.guestbtn
            }
            fullWidth
            onClick={() => {
              history.push("/signup");
            }}
          >
            Sign Up
          </Button>
          <Typography className={classes.poweredby}>
            *Powered by Webnyay
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default FrontPageHotstar;
